

export const MENU = [{
    name: 'Introducción',
    path: '/'
}, {
    name: 'Autenticación',
    path: '/autenticacion'
}, {
    name: 'Crear documento',
    path: '/documento',
    items: [
        {
            name: 'Subir Documento',
            path: '/documento/subir',
            items: [
                {
                    name: 'Desde url',
                    path: '/documento/subir/url'
                },
                {
                    name: 'Base64',
                    path: '/documento/subir/base64'
                },
                {
                    name: 'Utilizando un Template',
                    path: '/documento/subir/template'
                },
                {
                    name: 'Respuesta API',
                    path: '/documento/subir/respuesta'
                }
            ]
        },
        {
            name: 'Stickers / Firmantes',
            path: '/documento/stickers',
            items: [
                {
                    name: 'Recuadros de Firma',
                    path: '/documento/stickers/recuadro'
                },
                {
                    name: 'API',
                    path: '/documento/stickers/api'
                },
                {
                    name: 'Lineas de Firma',
                    path: '/documento/stickers/lineasfirma'
                }
            ]
        },
        {
            name: 'Flujos de firma',
            path: '/documento/flujos'
        },
        {
            name: 'Gestor',
            path: '/documento/gestor'
        },
        {
            name: 'Código QR',
            path: '/documento/qr'
        },
        {
            name: 'Expedientes',
            path: '/documento/expedientes',
            items: [
                {
                    name: 'Crear',
                    path: '/documento/expedientes/crear'
                },
                {
                    name: 'Agregar Documentos',
                    path: '/documento/expedientes/agregar'
                },
                {
                    name: 'Cerrar',
                    path: '/documento/expedientes/cerrar'
                },
                {
                    name: 'Consultar',
                    path: '/documento/expedientes/consultar'
                }
            ]
        },
        {
            name: 'Templates',
            path: '/documento/templates',
            items: [
                {
                    name: 'HTML',
                    path: '/documento/templates/html'
                },
                {
                    name: 'pdfmake',
                    path: '/documento/templates/pdfmake'
                }
            ]
        },
        {
            name: 'Correos certificados',
            path: '/documento/correosCertificados'
        }
    ]
}, {
    name: 'Acciones Documento',
    path: '/acciones',
    items: [
        {
            name: 'Endosos',
            path: '/acciones/endosos'
        },
        {
            name: 'Descargar documento',
            path: '/acciones/descargar'
        },
        {
            name: 'Editar firmantes',
            path: '/acciones/editarFirmantes'
        },
        {
            name: 'Flujo',
            path: '/acciones/flujo'
        },
        {
            name: 'Flujo expedientes',
            path: '/acciones/flujoExpedientes'
        },
        {
            name: 'Información',
            path: '/acciones/info'
        },
        {
            name: 'Eliminar',
            path: '/acciones/eliminar'
        },
        {
            name: 'Restaurar',
            path: '/acciones/restaurar'
        },
        {
            name: 'Descargar sello de tiempo',
            path: '/acciones/descargarSelloTiempo'
        },
        {
            name: 'Descargar historial',
            path: '/acciones/descargarHistorial'
        },
        {
            name: 'Descargar correo certificado',
            path: '/acciones/descargarCorreoCertificado'
        }
    ]
}, {
    name: 'Herramientas',
    path: '/tools',
    items: [
        {
            name: 'Tamaño de página PDF',
            path: '/herramientas/pageSize'
        }
    ]
},
{
    name: 'Webhooks',
    path: '/webhooks',
    items: [
        {
            name: 'Confirmación',
            path: '/webhooks/confirmacion'
        }
    ]
}, {
    name: 'Consultas',
    path: '/consultas',
    items: [
        {
            name: "Estado de paquetes contratados",
            path: '/consultas/cuenta'
        },
        {
            name: "Obtener documentos",
            path: '/consultas/listar'
        }
    ]
}, {
    name: 'Sello de tiempo NOM 151',
    path: '/nom151',
    items: [
        {
            name: 'Estampilla en PDF',
            path: '/nom151/pdf'
        },
        {
            name: 'Sello para cualquier archivo',
            path: '/nom151/otros'
        }
    ]
}, {
    name: 'Descargas',
    path: '/descargas'
}]
