import React from 'react';
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'

export function JS({ codeLang }) {

    switch (codeLang) {
        case 'http': return <Code lang="javascript">
            {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
GET /api/account
                `}
        </Code>
        default: return <Code lang="javascript">
            {`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "apikey");

let result = await services.getAccountInfo();

`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
            `
{
    // remaining y documents deben ser siempre el mismo numero
    "remaining": 1200,
    "documents": 1200,
    "verifications": 600,
    "whatsAppMessages": 2000
}
            `
        }
    </Code>
}


export function html() {
    return (
        <React.Fragment>
            <Subtitle>Estado de paquetes contratados</Subtitle>
            <div className="Content">
                <p>
                    Puedes consultar el estado actual de los paquetes que tengas contratados
                </p>

                <p>La respuesta incluye:</p>

                <ul>
                    <li><strong>documents:</strong> Documentos restantes</li>
                    <li><strong>verifications:</strong> Verificaciones de identidad restantes</li>
                    <li><strong>whatsAppMessages:</strong> Mensajes de WhatsApp restantes</li>
                </ul>

            </div>
        </React.Fragment>
    )
}

export default { JS, html, Response }
