import React from 'react';
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'
import ExternalLink from "../../shared/ExternalLink";

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
GET /api/document/{type}/{ticket}
                `}
                </Code>
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const response = await services.getDocument( 
    'universal',
    '04c0c65d-a06c-4f43-9a17-63b375b0106b'
)

`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
            `
// Descarga universal            
{
    "universal": "JVBERi0xLjcNCiW1tbW1..",
    "hash": "JyK0EkYQ4IJHNi/x2D77z+Ja8JH/jx6YilFJHuto9kU="
}


// Descarga original            
{
    "original": "JVBERi0xLjcNCiW1tbW1..",
    "hash": "JyK0EkYQ4IJHNi/x2D77z+Ja8JH/jx6YilFJHuto9kU="
}
`
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Descargar documento</Subtitle>
            <div className="Content">
                <p>
                    Una vez que ya diste de alta el documento, puedes descargarlo en cualquier momento especificando
                    los siguientes parametros:
                </p>
                <ul>
                    <li>
                        <strong>type:</strong> Versión del documento a descargar, las opciones son:
                        <ul>
                            <li>original</li>
                            <li>universal</li>
                        </ul>
                    </li>
                    <li>
                        <strong>firmamexId:</strong> id único del documento. <ExternalLink href="/docs/documento/subir/respuesta">En la respuesta recibida al subir el documento</ExternalLink> es el atributo "document_ticket"
                    </li>
                </ul>
                <p>
                    La respuesta del API contendrá dos atributos:
                    <ul>
                        <li>
                            <strong>original/universal:</strong> Documento en base64. Si se solicitó la versión original, el atributo será "original", si se solicitó la universal, será "universal"
                        </li>
                        <li>
                            <strong>hash:</strong> Hash SHA256 codificado en base64 del documento
                        </li>
                    </ul>
                </p>
            </div>
        </React.Fragment>
    )
}

export default { JS, html, Response }
