import React from 'react';
import Header from '../../shared/Header';
import Code from '../../shared/Code';
import CodeSection from '../../shared/CodeSection'
import webhooksImg from '../../images/webhooks.png';
import { Table } from 'reactstrap'

export function JS({ codeLang }) {

    switch (codeLang) {
        case 'python': return <Code lang="javascript">
            {``}
        </Code>
        default: return <Code lang="javascript">
            {``}
        </Code>
    }
}

export function html() {
    return (
        <React.Fragment>
            <Header>Webhooks</Header>
            <div className="Content">
            <p>
                Puedes especificar un webhook para recibir notificaciones en tu servidor cuando un documento
                ha sido firmado, revisarlo, y continuar con tu proceso.
            </p>
            <p>
                Para especificar un webhook, inicia sesión en tu cuenta de desarrollador en https://firmamex.com/developers/login.
                La URL que proporciones debe aceptar solicitudes HTTP POST con un JSON Content Type (application/json).
            </p>
            <figure style={{ textAlign: 'center' }}>
                <img src={webhooksImg} alt="webhooks" width="85%" />
            </figure>
            <br />
            <p>
                Cada vez que se firme un documento que tu generaste, Firmamex hará un POST a tu webhook con la siguiente
                información:
            </p>
            <ul>
                <li>
                    <strong>notification_id:</strong> Es el ID de la notificación, puedes utilizar este ID para solicitar más
                    información sobre el documento.
                </li>
                <li><strong>document_title:</strong> El título del documento que fué firmado.</li>
                <li><strong>firmamex_id:</strong> Un ID único para el documento.</li>
                <li><strong>text:</strong> Descripción del evento.</li>
                <li><strong>notification_type:</strong> Tipo de notificación.</li>
                <li><strong>signer_email:</strong> El correo del firmante.</li>
                <li><strong>signer_fingerprint:</strong> Fingerprint del certificado del firmante.</li>
                <li><strong>signer_data:</strong> Información adicional del firmante.</li>
                <li><strong>meta:</strong> Algunas notificaciones contendrán este campo. Incluye información adicional sobre la notificación</li>
            </ul>
            <br />
            <p>
                <strong>notification_type</strong>
                <p>
                    Existen los siguientes tipos de notificaciones:
                </p>
                <ol>
                    <li>
                        <a href="#original_signed">original_signed</a>
                    </li>
                    <li>
                        <a href="#universal_signed">universal_signed</a>
                    </li>
                    <li>
                        <a href="#document_completed">document_completed</a>
                    </li>
                    <li>
                        <a href="#document_rejected">document_rejected</a>
                    </li>
                    <li>
                        <a href="#workflow_expired">workflow_expired</a>
                    </li>
                    <li>
                        <a href="#sms_error">sms_error</a>
                        <ul>
                            <li>
                                <a href="#sms_error_codes">
                                    Códigos de error
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#email_error">email_error</a>
                    </li>
                    <li>
                        <a href="#original_nom151_stamped">original_nom151_stamped</a>
                    </li>
                    <li>
                        <a href="#universal_nom151_stamped">universal_nom151_stamped</a>
                    </li>
                    <li>
                        <a href="#identity_verification_failed">identity_verification_failed</a>
                        <div>Códigos de error</div>
                        <ul>
                            <li>
                                <a href="#iv_usability">USABILITY</a>
                            </li>
                            <li>
                                <a href="#iv_image_check">IMAGE_CHECK</a>
                            </li>
                            <li>
                                <a href="#iv_liveness">LIVENESS</a>
                            </li>
                            <li>
                                <a href="#iv_similarity">SIMILARITY</a>
                            </li>
                            <li>
                                <a href="#iv_data_check">DATA_CHECK</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#identity_verification_completed">identity_verification_completed</a>
                    </li>
                </ol>
            </p>
            <ul>
                <li>
                    <strong id="original_signed">original_signed:</strong> Un usuario a firmado este documento.
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                            {
                                "signer_data": {
                                    "id": 2000,
                                    "email": "fernando@firmamex.com",
                                    "fingerprint": "667483ad8b935fd6874477da9e822a60d6ab3b22",
                                    "authority": "Vinculada a Correo Electrónico por Liga",
                                    "name": "fernando@firmamex.com"
                                  },
                                  "signer_email": "fernando@firmamex.com",
                                  "signer_fingerprint": "667483ad8b935fd6874477da9e822a60d6ab3b22",
                                  "user": 1000,
                                  "signer": 2000,
                                  "notification_type": "original_signed",
                                  "notification_id": "2697",
                                  "document_title": "contrato.pdf",
                                  "firmamex_id": "04337396-e929-43ca-9b8a-ec958a44079d",
                                  "text": "Document 04337396-e929-43ca-9b8a-ec958a44079d.pdf just signed"
                              }
                            `
                        }
                    </CodeSection>
                </li>
                <li>
                    <strong id="universal_signed">universal_signed:</strong> La firma ha sido encapsulada.
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                            {
                                "signer_data": {
                                    "id": 2000,
                                    "email": "fernando@firmamex.com",
                                    "fingerprint": "667483ad8b935fd6874477da9e822a60d6ab3b22",
                                    "authority": "Vinculada a Correo Electrónico por Liga",
                                    "name": "fernando@firmamex.com"
                                  },
                                  "signer_email": "fernando@firmamex.com",
                                  "signer_fingerprint": "667483ad8b935fd6874477da9e822a60d6ab3b22",
                                  "user": 1000,
                                  "signer": 2000,
                                  "notification_type": "universal_signed",
                                  "notification_id": "2700",
                                  "document_title": "contrato.pdf",
                                  "firmamex_id": "04337396-e929-43ca-9b8a-ec958a44079d",
                                  "text": "Document 04337396-e929-43ca-9b8a-ec958a44079d.pdf just signed"
                              }
                            `
                        }
                    </CodeSection>
                </li>
                <li>
                    <strong id="document_completed">document_completed:</strong> Se han firmado todos los stickers.
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                            {
                                "notification_type": "document_completed",
                                "notification_id": "2698",
                                "document_title": "contrato.pdf",
                                "firmamex_id": "04337396-e929-43ca-9b8a-ec958a44079d",
                                "text": "Document 04337396-e929-43ca-9b8a-ec958a44079d.pdf has been completed"
                              }
                            `
                        }
                    </CodeSection>
                </li>
                <li>
                    <strong id="document_rejected">document_rejected:</strong> El firmante ha rechazado el documento.
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                            {
                                "meta": {
                                  // correo, rfc o telefono del firmante que rechazo el documento
                                  "signer": "GOCF9002226A7",
                                  // razon especificada por el usuario por la que el documento fue rechazado (300 caracteres)
                                  "reason": "solicito cambio en ..."
                                },
                                "notification_type": "document_rejected",
                                "notification_id": "2693",
                                "document_title": "contrato.pdf",
                                "firmamex_id": "38a81807-be84-4ec2-8d39-33a1b5c1d78a"
                              }
                            `
                        }
                    </CodeSection>
                </li>
                <li>
                    <strong id="workflow_expired">workflow_expired:</strong> El flujo de firma ha expirado.
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                            {
                                "notification_type": "workflow_expired",
                                "notification_id": "2693",
                                "document_title": "contrato.pdf",
                                "firmamex_id": "38a81807-be84-4ec2-8d39-33a1b5c1d78a"
                            }
                            `
                        }
                    </CodeSection>
                </li>
                <li>
                    <strong id="sms_error">sms_error:</strong> Sucedió un error al intentar enviar el SMS al firmante. El atributo "meta" contendrá
                    el número de teléfono y el tipo de error.

                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                            {
                                "meta": {
                                    "message": "Error interno de servidor",
                                    "code": "500"
                                  },
                                  "notification_type": "sms_error",
                                  "notification_id": "2702",
                                  "document_title": "contrato.pdf",
                                  "firmamex_id": "24ab99f2-eb3b-43c4-812d-2976490156e8"
                              }
                            `
                        }
                    </CodeSection>
                    <p id="sms_error_codes">
                        <a href="#sms_error_codes">
                            <strong>Códigos de error</strong>
                        </a>
                    </p>
                    <Table>
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Descripción</th>
                            <th>Recomendación</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>400</td>
                            <td>Texto del SMS no incluido</td>
                            <td>Corregir solicitud</td>
                        </tr>
                        <tr>
                            <td>401</td>
                            <td>Número celular del SMS no incluido</td>
                            <td>Corregir solicitud</td>
                        </tr>
                        <tr>
                            <td>402</td>
                            <td>Tamaño de mensaje excedido</td>
                            <td>Corregir solicitud</td>
                        </tr>
                        <tr>
                            <td>403</td>
                            <td>Numero incorrecto</td>
                            <td>Corregir solicitud</td>
                        </tr>
                        <tr>
                            <td>450</td>
                            <td>Error al enviar el mensaje</td>
                            <td>Reintentar solicitud</td>
                        </tr>
                        <tr>
                            <td>500</td>
                            <td>Error interno de servidor</td>
                            <td>Contactar a Firmamex</td>
                        </tr>
                        </tbody>
                    </Table>
                </li>
                <li>
                    <strong id="email_error">email_error:</strong> Sucedió un error al intentar enviar el correo al firmante. El atributo "meta" contendrá la información del error. Siempre tendrá al menos "message", "recipient" y "subject".
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                        {
                            "meta": {
                                // descripcion del error proporcionada por el servidor destino
                                "description": "",
                                // mensaje de error proporcionado por el servidor destino
                                "message": "5.1.1 The email account that you tried to reach does not exist.",
                                // host del servidor destino
                                "mx-host": "aspmx.l.google.com",
                                // codigo de error smtp
                                "code": "550",
                                // direccion a la que se intento enviar el correo
                                "recipient": "fernando@firmamex.com",
                                // asunto del correo
                                "subject": "Ha sido invitado a firmar un documento Firmamex:kYb"
                              },
                            "notification_type": "email_error",
                            "notification_id": "2693",
                            "document_title": "contrato.pdf",
                            "firmamex_id": "38a81807-be84-4ec2-8d39-33a1b5c1d78a"
                        }
                            `
                        }
                    </CodeSection>
                </li>
            </ul>
            <br />
            <br/>
            <ul>
                <li><strong id="original_nom151_stamped">original_nom151_stamped:</strong> Se ha realizado el sello NOM 151 sobre el documento original.</li>
                <CodeSection lang="js" height="220px" label="Ejemplo">
                    {
                        `
                            {
                                "meta": {
                                    "hash": "017a330e54ba39ec30919b41d96bf9bc2a3b64c259158b1d88f1c59f8e21c2d3",
                                    "tsa": "L=MIGUEL HIDALGO,ST=CIUDAD DE MEXICO,C=MX,CN=CECOBAN-PSC-NOM151-1-01,OU=Thales TSS ESN:995D-C54C-D22E,O=CECOBAN SA DE CV",
                                    "policy": "2.16.484.101.10.316.2.3.1.2.1.1.1",
                                    "genTime": "2021-03-30T02:34:24.582Z"
                                  },
                                  "notification_type": "original_nom151_stamped",
                                  "notification_id": "2699",
                                  "document_title": "contrato.pdf",
                                  "firmamex_id": "04337396-e929-43ca-9b8a-ec958a44079d"
                              }
                            `
                    }
                </CodeSection>
                <li>
                    <strong id="universal_nom151_stamped">universal_nom151_stamped:</strong> Se ha realizado el sello NOM 151 sobre el documento universal.
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                            {
                                "meta": {
                                    "hash": "017a330e54ba39ec30919b41d96bf9bc2a3b64c259158b1d88f1c59f8e21c2d3",
                                    "tsa": "L=MIGUEL HIDALGO,ST=CIUDAD DE MEXICO,C=MX,CN=CECOBAN-PSC-NOM151-1-01,OU=Thales TSS ESN:995D-C54C-D22E,O=CECOBAN SA DE CV",
                                    "policy": "2.16.484.101.10.316.2.3.1.2.1.1.1",
                                    "genTime": "2021-03-30T02:34:24.582Z"
                                  },
                                  "notification_type": "universal_nom151_stamped",
                                  "notification_id": "2701",
                                  "document_title": "contrato.pdf",
                                  "firmamex_id": "04337396-e929-43ca-9b8a-ec958a44079d"
                              }
                            `
                        }
                    </CodeSection>
                </li>
            </ul>
                <br/>
                <br/>
                <ul>
                    <li><strong id="identity_verification_failed">identity_verification_failed:</strong> Sucedió un error en la verificación de identidad</li>
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
{
    "meta": [
      {
        "status": "FAILURE",
        "date": "2025-03-24T04:11:42.000Z",
        "errors": [
          {
            "category": "USABILITY",
            "message": "PHOTOCOPY"
          }
        ]
      }
    ],
    "notification_type": "identity_verification_failed",
    "notification_id": "2701",
    "first_notification_date": "2025-03-25T01:53:46Z",
    "document_title": "documento.pdf",
    "firmamex_id": "83868364-4a30-4d11-9c9b-465fcab082"
  }
                            `
                        }
                    </CodeSection>
                    <br/>
                    <p id="iv_usability">
                        <a href="#iv_usability">
                            <strong>Códigos de error de USABILITY</strong>
                        </a>
                    </p>
                    <Table>
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Descripción</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>TECHNICAL_ERROR</td>
                            <td>Ocurrió un error que impidió la ejecución.</td>
                        </tr>
                        <tr>
                            <td>NOT_UPLOADED</td>
                            <td>La imagen no fue cargada.</td>
                        </tr>
                        <tr>
                            <td>BLACK_WHITE</td>
                            <td>No se admiten imágenes en blanco y negro.</td>
                        </tr>
                        <tr>
                            <td>MISSING_SIGNATURE</td>
                            <td>No se detectó ninguna firma.</td>
                        </tr>
                        <tr>
                            <td>MISSING_PAGE</td>
                            <td>
                                Se requerían las páginas frontal y posterior del documento, pero el
                                usuario cargó solo una de ellas.
                            </td>
                        </tr>
                        <tr>
                            <td>NOT_A_DOCUMENT</td>
                            <td>La imagen no es un documento reconocido.</td>
                        </tr>
                        <tr>
                            <td>PHOTOCOPY</td>
                            <td>
                                El documento en la imagen es una fotocopia y no el documento original.
                            </td>
                        </tr>
                        <tr>
                            <td>BAD_QUALITY_IMAGE</td>
                            <td>La imagen es de calidad insuficiente para el procesamiento.</td>
                        </tr>
                        <tr>
                            <td>BLURRED</td>
                            <td>Imagen borrosa.</td>
                        </tr>
                        <tr>
                            <td>GLARE</td>
                            <td>El ID está oscurecido por el reflejo.</td>
                        </tr>
                        <tr>
                            <td>PART_OF_DOCUMENT_MISSING</td>
                            <td>Parte del documento fue excluida de la imagen.</td>
                        </tr>
                        <tr>
                            <td>PART_OF_DOCUMENT_HIDDEN</td>
                            <td>Parte del ID está oculta en la imagen cargada.</td>
                        </tr>
                        <tr>
                            <td>DAMAGED_DOCUMENT</td>
                            <td>
                                El documento está dañado hasta el punto en que los datos son difíciles
                                de extraer o las características de seguridad son difíciles de
                                verificar.
                            </td>
                        </tr>
                        <tr>
                            <td>MISSING_MANDATORY_DATAPOINTS</td>
                            <td>
                                Uno o más de los campos de datos clave, como Nombre, Apellido o Fecha de
                                nacimiento, no se pueden extraer.
                            </td>
                        </tr>
                        <tr>
                            <td>DOCUMENT_CONTENT_NOT_SUPPORTED</td>
                            <td>
                                Solo se utiliza para credenciales de Documento, cuando el contenido del
                                documento no se ajusta al tipo especificado en la solicitud de la
                                cuenta.
                            </td>
                        </tr>
                        <tr>
                            <td>DIGITAL_COPY</td>
                            <td>La imagen del ID es de una pantalla.</td>
                        </tr>
                        <tr>
                            <td>UNSUPPORTED_COUNTRY</td>
                            <td>
                                El documento fue emitido por un país que no está configurado para su
                                organización.
                            </td>
                        </tr>
                        <tr>
                            <td>UNSUPPORTED_DOCUMENT_TYPE</td>
                            <td>El tipo de documento no es compatible.</td>
                        </tr>
                        </tbody>
                    </Table>
                    <br/>
                    <p id="iv_image_check">
                        <a href="#iv_image_check">
                            <strong>Códigos de error de IMAGE_CHECK</strong>
                        </a>
                    </p>
                    <Table>
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Descripción</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>PRECONDITION_NOT_FULFILLED</td>
                            <td>Los datos requeridos de otra capacidad no están disponibles.</td>
                        </tr>
                        <tr>
                            <td>TECHNICAL_ERROR</td>
                            <td>Ocurrió un error que impidió la ejecución.</td>
                        </tr>
                        <tr>
                            <td>FAILED</td>
                            <td>
                                Las imágenes del documento de identidad no pasaron todas las
                                comprobaciones de imagen.
                            </td>
                        </tr>
                        <tr>
                            <td>WATERMARK</td>
                            <td>
                                La imagen del ID contiene una marca de agua (por ejemplo, "Muestra").
                            </td>
                        </tr>
                        <tr>
                            <td>OTHER_REJECTION</td>
                            <td>
                                La imagen del ID fue rechazada por una razón que no se incluye en las
                                otras etiquetas de decisión.
                            </td>
                        </tr>
                        <tr>
                            <td>GHOST_IMAGE_DIFFERENT</td>
                            <td>
                                Hay una discrepancia entre la imagen principal del ID y la imagen
                                fantasma.
                            </td>
                        </tr>
                        <tr>
                            <td>PUNCHED</td>
                            <td>El ID ha sido perforado.</td>
                        </tr>
                        <tr>
                            <td>SAMPLE</td>
                            <td>El ID es un documento de muestra conocido.</td>
                        </tr>
                        <tr>
                            <td>FAKE</td>
                            <td>
                                El ID es falso. Las posibles razones incluyen: El ID es una falsificación
                                conocida. La imagen está disponible en Internet público.
                            </td>
                        </tr>
                        <tr>
                            <td>CHIP_MISSING</td>
                            <td>El ID tiene un chip faltante.</td>
                        </tr>
                        <tr>
                            <td>DIGITAL_MANIPULATION</td>
                            <td>La imagen del ID fue manipulada antes de ser cargada.</td>
                        </tr>
                        <tr>
                            <td>MISMATCH_FRONT_BACK</td>
                            <td>
                                El país que se muestra en el anverso del ID no coincide con el que se
                                muestra en el reverso.
                            </td>
                        </tr>
                        <tr>
                            <td>MANIPULATED_DOCUMENT</td>
                            <td>
                                El ID muestra una foto o texto superpuesto físicamente. Nota: En la
                                mayoría de los casos, se devolverá una de las etiquetas más detalladas
                                que se muestran a continuación.
                            </td>
                        </tr>
                        <tr>
                            <td>MANIPULATED_DOCUMENT_PHOTO</td>
                            <td>El ID muestra una foto o texto superpuesto físicamente.</td>
                        </tr>
                        <tr>
                            <td>MANIPULATED_DOCUMENT_DOCUMENT_NUMBER</td>
                            <td>
                                El número de documento en el ID muestra manipulaciones físicas o
                                digitales visibles.
                            </td>
                        </tr>
                        <tr>
                            <td>MANIPULATED_DOCUMENT_EXPIRY</td>
                            <td>
                                La fecha de vencimiento en el ID muestra manipulaciones físicas o
                                digitales visibles.
                            </td>
                        </tr>
                        <tr>
                            <td>MANIPULATED_DOCUMENT_DOB</td>
                            <td>
                                La fecha de nacimiento en el ID muestra manipulaciones físicas o
                                digitales visibles.
                            </td>
                        </tr>
                        <tr>
                            <td>MANIPULATED_DOCUMENT_NAME</td>
                            <td>
                                El nombre en el ID muestra manipulaciones físicas o digitales visibles.
                            </td>
                        </tr>
                        <tr>
                            <td>MANIPULATED_DOCUMENT_ADDRESS</td>
                            <td>
                                La dirección en el ID muestra manipulaciones físicas o digitales
                                visibles.
                            </td>
                        </tr>
                        <tr>
                            <td>MANIPULATED_DOCUMENT_SECURITY_CHECKS</td>
                            <td>
                                Un agente de back-office revisó el escaneo y lo rechazó debido a un
                                problema con las funciones de seguridad. Por ejemplo, la microimpresión
                                o el patrón de fondo no coinciden con la imagen de muestra, o falta un
                                logotipo o imagen o no está en línea con la muestra.
                            </td>
                        </tr>
                        <tr>
                            <td>MANIPULATED_DOCUMENT_SIGNATURE</td>
                            <td>
                                La firma en el ID muestra manipulaciones físicas o digitales visibles.
                            </td>
                        </tr>
                        <tr>
                            <td>MANIPULATED_DOCUMENT_PERSONAL_NUMBER</td>
                            <td>
                                El número personal en el ID muestra manipulaciones físicas o digitales
                                visibles.
                            </td>
                        </tr>
                        <tr>
                            <td>MANIPULATED_DOCUMENT_PLACE_OF_BIRTH</td>
                            <td>
                                El lugar de nacimiento en el ID muestra manipulaciones físicas o
                                digitales visibles.
                            </td>
                        </tr>
                        <tr>
                            <td>MANIPULATED_DOCUMENT_GENDER</td>
                            <td>
                                El género en el ID muestra manipulaciones físicas o digitales visibles.
                            </td>
                        </tr>
                        <tr>
                            <td>MANIPULATED_DOCUMENT_ISSUING_DATE</td>
                            <td>
                                La fecha de emisión en el ID muestra manipulaciones físicas o digitales
                                visibles.
                            </td>
                        </tr>
                        <tr>
                            <td>DIFFERENT_PERSON</td>
                            <td>
                                Cuando el usuario envía una imagen que incluye tanto una cara como un ID,
                                la cara no coincide con la persona en el ID.
                            </td>
                        </tr>
                        <tr>
                            <td>REPEATED_FACE</td>
                            <td>
                                La misma cara se ha visto en una transacción anterior
                            </td>
                        </tr>
                        <tr>
                            <td>GHOST_IMAGE_QUALITY_INSUFFICIENT</td>
                            <td>
                                La imagen fantasma está demasiado desvanecida para que nuestros modelos
                                determinen con confianza si está disponible.
                            </td>
                        </tr>
                        <tr>
                            <td>LOOKUPS_HIGH_RISK_FACE</td>
                            <td>
                                Se confirmó que una coincidencia de rostro era un fraude
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    <br/>
                    <p id="iv_liveness">
                        <a href="#iv_liveness">
                            <strong>Códigos de error de LIVENESS</strong>
                        </a>
                    </p>
                    <Table>
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Descripción</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>PRECONDITION_NOT_FULFILLED</td>
                            <td>Los datos requeridos de otra capacidad no están disponibles.</td>
                        </tr>
                        <tr>
                            <td>TECHNICAL_ERROR</td>
                            <td>Ocurrió un error que impidió la ejecución.</td>
                        </tr>
                        <tr>
                            <td>LIVENESS_UNDETERMINED</td>
                            <td>No se puede determinar la prueba de vida.</td>
                        </tr>
                        <tr>
                            <td>ID_USED_AS_SELFIE</td>
                            <td>Se utilizó una foto de identificación como selfie.</td>
                        </tr>
                        <tr>
                            <td>MULTIPLE_PEOPLE</td>
                            <td>Aparece más de una persona en el selfie.</td>
                        </tr>
                        <tr>
                            <td>DIGITAL_COPY</td>
                            <td>El selfie fue capturado desde una pantalla.</td>
                        </tr>
                        <tr>
                            <td>PHOTOCOPY</td>
                            <td>El selfie fue capturado desde una impresión en papel.</td>
                        </tr>
                        <tr>
                            <td>MANIPULATED</td>
                            <td>
                                El selfie fue manipulado antes de ser cargado (por ejemplo, un cambio de
                                fondo).
                            </td>
                        </tr>
                        <tr>
                            <td>NO_FACE_PRESENT</td>
                            <td>No aparece ninguna cara en el selfie.</td>
                        </tr>
                        <tr>
                            <td>FACE_NOT_FULLY_VISIBLE</td>
                            <td>La cara solo es parcialmente visible en el selfie.</td>
                        </tr>
                        <tr>
                            <td>BLACK_WHITE</td>
                            <td>La imagen del selfie está en blanco y negro.</td>
                        </tr>
                        <tr>
                            <td>AGE_DIFFERENCE</td>
                            <td>
                                Hay una gran diferencia entre la edad estimada del selfie y la fecha de
                                nacimiento en el ID.
                            </td>
                        </tr>
                        <tr>
                            <td>BAD_QUALITY</td>
                            <td>El selfie es de mala calidad.</td>
                        </tr>
                        </tbody>
                    </Table>
                    <br/>
                    <p id="iv_similarity">
                        <a href="#iv_similarity">
                            <strong>Códigos de error de SIMILARITY</strong>
                        </a>
                    </p>
                    <Table>
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Descripción</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>PRECONDITION_NOT_FULFILLED</td>
                            <td>Los datos requeridos de otra capacidad no están disponibles.</td>
                        </tr>
                        <tr>
                            <td>TECHNICAL_ERROR</td>
                            <td>Ocurrió un error que impidió la ejecución.</td>
                        </tr>
                        <tr>
                            <td>MATCH</td>
                            <td>
                                Las caras en las imágenes que se comparan pertenecen a la misma persona.
                            </td>
                        </tr>
                        <tr>
                            <td>NO_MATCH</td>
                            <td>Las caras en el selfie y el ID no coinciden.</td>
                        </tr>
                        <tr>
                            <td>NOT_POSSIBLE</td>
                            <td>No se puede determinar la similitud.</td>
                        </tr>
                        </tbody>
                    </Table>
                    <br/>
                    <p id="iv_data_check">
                        <a href="#iv_data_check">
                            <strong>Códigos de error de DATA_CHECK</strong>
                        </a>
                    </p>
                    <Table>
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Descripción</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>PRECONDITION_NOT_FULFILLED</td>
                            <td>Los datos requeridos de otra capacidad no están disponibles.</td>
                        </tr>
                        <tr>
                            <td>TECHNICAL_ERROR</td>
                            <td>Ocurrió un error que impidió la ejecución.</td>
                        </tr>
                        <tr>
                            <td>DOCUMENT_EXPIRY_WITHIN_CONFIGURED_LIMIT</td>
                            <td>El documento se acerca a su fecha de vencimiento.</td>
                        </tr>
                        <tr>
                            <td>HIGH_FRAUD_RISK</td>
                            <td>Existe una alta probabilidad de fraude.</td>
                        </tr>
                        <tr>
                            <td>NFC_CERTIFICATE</td>
                            <td>
                                Hay una discrepancia entre los datos extraídos del microchip del pasaporte
                                y los datos extraídos del documento mediante OCR. Este motivo de rechazo
                                solo se aplica a las transacciones que utilizan NFC en un dispositivo
                                móvil.
                            </td>
                        </tr>
                        <tr>
                            <td>MISMATCHING_DATAPOINTS</td>
                            <td>
                                Hay una discrepancia entre diferentes ocurrencias de un punto de datos
                                repetido en un documento. Por ejemplo, los datos extraídos del código de
                                barras no coinciden con los datos impresos extraídos del ID.
                            </td>
                        </tr>
                        <tr>
                            <td>MRZ_CHECKSUM</td>
                            <td>
                                La suma de comprobación de la zona de lectura mecánica (MRZ) tiene un
                                valor inesperado.
                            </td>
                        </tr>
                        <tr>
                            <td>MISMATCHING_DATA_REPEATED_FACE</td>
                            <td>
                                El ID o el selfie coincide con un selfie o ID cargado previamente, pero
                                los datos personales correspondientes no coinciden.
                            </td>
                        </tr>
                        <tr>
                            <td>MISMATCH_HRZ_MRZ_DATA</td>
                            <td>
                                Los datos en la zona legible por humanos (HRZ) y la zona de lectura
                                mecánica (MRZ) del ID no coinciden.
                            </td>
                        </tr>
                        <tr>
                            <td>CUSTOMER_FEEDBACK</td>
                            <td>
                                Los datos del ID coinciden con los datos de una transacción anterior que
                                fue etiquetada como fraude a través de la función de comentarios de la
                                transacción.
                            </td>
                        </tr>
                        <tr>
                            <td>ID_DATA_MISMATCH</td>
                            <td>
                                Se detecta una discrepancia significativa entre las instancias de un
                                campo de datos.
                            </td>
                        </tr>
                        <tr>
                            <td>LOOKUPS_MEDIUM_RISK_IP_ADDRESS</td>
                            <td>
                                Se ha detectado una velocidad de transacciones desde una dirección IP
                                común.
                            </td>
                        </tr>
                        <tr>
                            <td>LOOKUPS_MEDIUM_RISK_DEVICE</td>
                            <td>
                                Se ha detectado una velocidad de transacciones desde un dispositivo común.
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    <li>
                        <strong id="identity_verification_completed">identity_verification_completed:</strong> Se ha completado una verificación de identidad exitosamente
                        <CodeSection lang="js" height="220px" label="Ejemplo">
                            {
                                `
{
    "meta": [
      {
        "status": "FAILURE",
        "date": "2025-03-24T04:11:42.000Z",
        "errors": [
          {
            "category": "USABILITY", 
            "message": "PHOTOCOPY"
          }
        ]
      },
      {
        "status": "SUCCESS",
        "date": "2025-03-25T10:38:11.000Z",
        "errors": []
      },
    ],
    "notification_type": "identity_verification_completed",
    "notification_id": "2701",
    "first_notification_date": "2025-03-25T01:53:46Z",
    "document_title": "documento.pdf",
    "firmamex_id": "83868364-4a30-4d11-9c9b-465fcab082"
  }
                            `
                            }
                        </CodeSection>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    )
}

export default { JS, html }
