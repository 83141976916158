import React, { useState } from 'react';
import MenuItem from './shared/MenuItem'
import { MENU } from './Consts'
import Intro from './pages/Intro'
import Autenticacion from './pages/Autenticacion'
import URL from './pages/subirDocumento/url';
import Base64 from './pages/subirDocumento/base64';
import EnviarTemplate from './pages/subirDocumento/template';
import EnvioRespuesta from './pages/subirDocumento/respuesta';
import Subir from './pages/subirDocumento/subir';
import IndexDocumento from './pages/IndexDocumento';
import StickersAPI from './pages/stickers/StickersAPI';
import Stickers from './pages/stickers';
import { Route } from "react-router-dom";
import { FormGroup, Input } from 'reactstrap'
import Recuadro from './pages/stickers/Recuadro';
import LineasFirma from './pages/stickers/LineasFirma';
import Flujos from './pages/flujos';
import QR from './pages/qr';
import PageSize from './pages/herramientas/pageSize';
import Acciones from './pages/acciones/index';
import Descargar from './pages/acciones/descargar';
import EditarFlujo from './pages/acciones/editarFlujo'
import Info from './pages/acciones/info';
import Eliminar from './pages/acciones/eliminar';
import Restaurar from './pages/acciones/restaurar';
import DescargarCorreoCertificado from './pages/acciones/descargarCorreoCertificado'
import EditarFirmantes from './pages/acciones/editarFirmantes';
import Endosos from './pages/acciones/endosos';
import FlujoExpedientes from './pages/acciones/flujoExpedientes';
import DescargarSelloTiempo from './pages/acciones/descargarSelloTiempo';
import DescargarHistorial from './pages/acciones/descargarHistorial';
import Webhooks from './pages/webhooks';
import Confirmacion from './pages/webhooks/Confirmacion';
import Expedientes from './pages/expedientes';
import Crear from './pages/expedientes/Crear';
import Agregar from './pages/expedientes/Agregar';
import Cerrar from './pages/expedientes/Cerrar';
import Consultar from './pages/expedientes/Consultar';
import NOM151 from './pages/nom151';
import PDF from './pages/nom151/Pdf';
import Otros from './pages/nom151/Otros';
import Templates from './pages/templates';
import TemplatesHtml from './pages/templates/html'
import TemplatesPdfMake from './pages/templates/pdfmake'
import Descargas from './pages/Descargas';
import FirmamexFull from './images/full_logo.svg';
import CorreosCertificados from './pages/correosCertificados'

import ConsultasListar from './pages/consultas/consultasListar';
import InformacionCuenta from './pages/consultas/informacionCuenta'
import IndexConsultas from './pages/consultas/indexConsultas';
import Gestor from "./pages/gestor";
import CodeSection from "./shared/CodeSection";
import Code from "./shared/Code";

function WithResponse({JS, Response, codeLang}) {
    return (
        <div>
            <div className="CodebarSectionTitle">Solicitud</div>
            <JS codeLang={codeLang}></JS>
            <br />
            <div className="CodebarSectionTitle">Respuesta</div>
            <Response></Response>
        </div>
    )
}


function WithResponseAndError({JS, Response, Errors, codeLang}) {
    return (
        <div>
            <div className="CodebarSectionTitle">Solicitud</div>
            <JS codeLang={codeLang}></JS>
            <br />
            {
                Response ?
                    <React.Fragment>
                        <div className="CodebarSectionTitle">Respuesta</div>
                        <Response></Response>
                    </React.Fragment> : <div></div>
            }
                    <React.Fragment>
                        <div className="CodebarSectionTitle">Errores</div>
                        <div className="CodebarSectionTitle">Si hay un error en la solicitud, la respuesta será un json con el atributo error</div>

                        <Code lang="js">
                            {
                                `{
 error: 'mensaje'
}
`
                            }
                        </Code>
                    </React.Fragment>
        </div>
    )
}

export default function Main() {

    const [codeLang, setCodeLang] = useState('javascript')

    function handleCodeLangChange(event) {
        setCodeLang(event.target.value)
    }

    return (
        <div className="MainContainer">
            <div>
                <div className="Sidebar">
                    <figure style={{ textAlign: 'center' }}>
                        <img src={FirmamexFull} alt="Firmamex" width="200px" />
                    </figure>
                    <p style={{ textAlign: 'center' }}>Documentación API</p><br />
                    {
                        MENU.map((m) => (
                            <MenuItem
                                key={m.path}
                                path={m.path}
                                items={m.items}
                                name={m.name}></MenuItem>
                        ))
                    }
                </div>
            </div>

            <div className="MainContent">
                <Route path="/docs" exact component={Intro}></Route>
                <Route path="/docs/autenticacion" exact component={Autenticacion.html}></Route>
                <Route path="/docs/documento" exact component={IndexDocumento.html}></Route>
                <Route path="/docs/documento/subir" component={Subir.html}></Route>
                <Route path="/docs/documento/subir/url" exact component={URL.html}></Route>
                <Route path="/docs/documento/subir/base64" exact component={Base64.html}></Route>
                <Route path="/docs/documento/subir/template" exact component={EnviarTemplate.html}></Route>
                <Route path="/docs/documento/subir/respuesta" exact component={EnvioRespuesta.html}></Route>
                <Route path="/docs/documento/stickers" component={Stickers.html}></Route>
                <Route path="/docs/documento/stickers/api" exact component={StickersAPI.html}></Route>
                <Route path="/docs/documento/stickers/recuadro" exact component={Recuadro.html}></Route>
                <Route path="/docs/documento/stickers/lineasfirma" exact component={LineasFirma.html}></Route>
                <Route path="/docs/documento/flujos" exact component={Flujos.html}></Route>
                <Route path="/docs/documento/qr" exact component={QR.html}></Route>
                <Route path="/docs/documento/gestor" exact component={Gestor.html}></Route>
                <Route path="/docs/herramientas/pageSize" exact component={PageSize.html}></Route>
                <Route path="/docs/acciones" component={Acciones.html}></Route>
                <Route path="/docs/acciones/endosos" exact component={Endosos.html}></Route>
                <Route path="/docs/acciones/descargar" exact component={Descargar.html}></Route>
                <Route path="/docs/acciones/flujo" exact component={EditarFlujo.html}></Route>
                <Route path="/docs/acciones/info" exact component={Info.html}></Route>
                <Route path="/docs/acciones/eliminar" exact component={Eliminar.html}></Route>
                <Route path="/docs/acciones/restaurar" exact component={Restaurar.html}></Route>
                <Route path="/docs/acciones/descargarCorreoCertificado" exact component={DescargarCorreoCertificado.html}></Route>
                <Route path="/docs/acciones/editarFirmantes" exact component={EditarFirmantes.html}></Route>
                <Route path="/docs/acciones/flujoExpedientes" exact component={FlujoExpedientes.html}></Route>
                <Route path="/docs/acciones/descargarSelloTiempo" exact component={DescargarSelloTiempo.html}></Route>
                <Route path="/docs/acciones/descargarHistorial" exact component={DescargarHistorial.html}></Route>
                <Route path="/docs/webhooks" component={Webhooks.html}></Route>
                <Route path="/docs/webhooks/confirmacion" exact component={Confirmacion.html}></Route>
                {/* <Route path="/docs/consultas" component={Consultas.html}></Route> */}
                <Route path="/docs/consultas" exact component={IndexConsultas.html}></Route>
                <Route path="/docs/consultas/listar" component={ConsultasListar.html}></Route>
                <Route path="/docs/consultas/cuenta" component={InformacionCuenta.html}></Route>
                <Route path="/docs/documento/expedientes" component={Expedientes.html}></Route>
                <Route path="/docs/documento/expedientes/crear" exact component={Crear.html}></Route>
                <Route path="/docs/documento/expedientes/agregar" exact component={Agregar.html}></Route>
                <Route path="/docs/documento/expedientes/cerrar" exact component={Cerrar.html}></Route>
                <Route path="/docs/documento/expedientes/consultar" exact component={Consultar.html}></Route>
                <Route path="/docs/nom151" component={NOM151.html}></Route>
                <Route path="/docs/nom151/pdf" exact component={PDF.html}></Route>
                <Route path="/docs/nom151/otros" exact component={Otros.html}></Route>
                <Route path="/docs/documento/templates" exact component={Templates.html}></Route>
                <Route path="/docs/documento/templates/html" exact component={TemplatesHtml.html}></Route>
                <Route path="/docs/documento/templates/pdfmake" exact component={TemplatesPdfMake.html}></Route>
                <Route path="/docs/documento/correosCertificados" exact component={CorreosCertificados.html}></Route>
                <Route path="/docs/descargas" exact component={Descargas.html}></Route>
            </div>
            <div className="Codebar CodebarContent">
                <FormGroup>
                    <Input type="select"
                        value={codeLang}
                        onChange={handleCodeLangChange}
                        className="CodeLangSelect">
                        <option value="javascript">Node</option>
                        <option value="http">HTTP</option>
                    </Input>
                </FormGroup>
                <div style={{ padding: '30px' }}>
                    <Route
                        path="/docs/autenticacion"
                        render={() =>
                            <Autenticacion.JS codeLang={codeLang}></Autenticacion.JS>}
                    ></Route>
                    <Route path="/docs/documento/subir/url"
                        render={() =>
                            <WithResponse
                                JS={URL.JS}
                                Response={URL.Response}
                                codeLang={codeLang}></WithResponse>
                        }></Route>
                    <Route path="/docs/documento/subir/base64"
                        render={() =>
                            <WithResponse
                                JS={Base64.JS}
                                Response={Base64.Response}
                                codeLang={codeLang}></WithResponse>
                        }></Route>
                    <Route path="/docs/documento/subir/respuesta"
                        render={() =>
                            <EnvioRespuesta.JS codeLang={codeLang}></EnvioRespuesta.JS>
                        }></Route>
                    <Route path="/docs/documento/stickers/api"
                        render={() =>
                            <WithResponse
                                JS={StickersAPI.JS}
                                Response={StickersAPI.Response}
                                codeLang={codeLang}></WithResponse>
                        }></Route>
                    <Route path="/docs/documento/stickers/recuadro"
                        render={() =>
                            <Recuadro.JS codeLang={codeLang}></Recuadro.JS>
                        }></Route>
                    <Route path="/docs/documento/flujos"
                        render={() =>
                            <Flujos.JS codeLang={codeLang}></Flujos.JS>
                        }></Route>

                    <Route path="/docs/documento/gestor"
                           render={() =>
                               <WithResponseAndError
                                   JS={Gestor.JS}
                                   Errors={Gestor.Errors}
                                   codeLang={codeLang}></WithResponseAndError>
                           }></Route>
                    <Route path="/docs/documento/qr"
                        render={() =>
                            <QR.JS codeLang={codeLang}></QR.JS>
                        }></Route>
                    <Route path="/docs/herramientas/pageSize"
                        render={() =>
                            <WithResponse
                                JS={PageSize.JS}
                                Response={PageSize.Response}
                                codeLang={codeLang}></WithResponse>
                        }></Route>

                    <Route path="/docs/acciones/endosos"
                           render={() =>
                               <WithResponse
                                   JS={Endosos.JS}
                                   Response={Endosos.Response}
                                   codeLang={codeLang}></WithResponse>
                           }></Route>
                    <Route path="/docs/acciones/descargar"
                        render={() =>
                            <WithResponse
                                JS={Descargar.JS}
                                Response={Descargar.Response}
                                codeLang={codeLang}></WithResponse>
                        }></Route>
                    <Route path="/docs/acciones/flujo"
                        render={() =>
                            <WithResponse
                                JS={EditarFlujo.JS}
                                Response={EditarFlujo.Response}
                                codeLang={codeLang}></WithResponse>
                        }></Route>
                    <Route path="/docs/acciones/info"
                        render={() =>
                            <WithResponse
                                JS={Info.JS}
                                Response={Info.Response}
                                codeLang={codeLang}></WithResponse>
                        }></Route>
                    <Route path="/docs/acciones/eliminar"
                        render={() =>
                            <WithResponse
                                JS={Eliminar.JS}
                                Response={Eliminar.Response}
                                codeLang={codeLang}></WithResponse>
                        }></Route>
                    <Route path="/docs/acciones/restaurar"
                        render={() =>
                            <WithResponse
                                JS={Restaurar.JS}
                                Response={Restaurar.Response}
                                codeLang={codeLang}></WithResponse>
                        }></Route>
                    <Route path="/docs/acciones/descargarCorreoCertificado"
                        render={() =>
                            <WithResponse
                                JS={DescargarCorreoCertificado.JS}
                                Response={DescargarCorreoCertificado.Response}
                                codeLang={codeLang}></WithResponse>
                        }></Route>
                    <Route path="/docs/acciones/editarFirmantes"
                        render={() =>
                            <WithResponse
                                JS={EditarFirmantes.JS}
                                Response={EditarFirmantes.Response}
                                codeLang={codeLang}></WithResponse>
                        }></Route>
                    <Route path="/docs/acciones/descargarSelloTiempo"
                        render={() =>
                            <WithResponse
                                JS={DescargarSelloTiempo.JS}
                                Response={DescargarSelloTiempo.Response}
                                codeLang={codeLang}></WithResponse>
                        }></Route>
                    <Route path="/docs/acciones/descargarHistorial"
                           render={() =>
                               <WithResponse
                                   JS={DescargarHistorial.JS}
                                   Response={DescargarHistorial.Response}
                                   codeLang={codeLang}></WithResponse>
                           }></Route>
                    <Route path="/docs/acciones/flujoExpedientes"
                        render={() =>
                            <WithResponse
                                JS={FlujoExpedientes.JS}
                                Response={FlujoExpedientes.Response}
                                codeLang={codeLang}></WithResponse>
                        }></Route>
                    <Route path="/docs/consultas/listar"
                        render={() =>
                            <WithResponse
                                JS={ConsultasListar.JS}
                                Response={ConsultasListar.Response}
                                codeLang={codeLang}></WithResponse>
                        }></Route>
                    <Route path="/docs/consultas/cuenta"
                           render={() =>
                               <WithResponse
                                   JS={InformacionCuenta.JS}
                                   Response={InformacionCuenta.Response}
                                   codeLang={codeLang}></WithResponse>
                           }></Route>
                    <Route path="/docs/webhooks"
                        render={() =>
                            <Webhooks.JS codeLang={codeLang}></Webhooks.JS>
                        }></Route>
                    <Route path="/docs/documento/expedientes/crear"
                        render={() =>
                            <WithResponse
                                JS={Crear.JS}
                                codeLang={codeLang}
                                Response={Crear.Response}></WithResponse>
                        }></Route>
                    <Route path="/docs/documento/expedientes/agregar"
                        render={() =>
                            <WithResponse
                                JS={Agregar.JS}
                                codeLang={codeLang}
                                Response={Agregar.Response}></WithResponse>
                        }></Route>
                    <Route path="/docs/documento/expedientes/cerrar"
                        render={() =>
                            <WithResponse
                                JS={Cerrar.JS}
                                codeLang={codeLang}
                                Response={Cerrar.Response}></WithResponse>
                        }></Route>
                    <Route path="/docs/documento/expedientes/consultar"
                        render={() =>
                            <WithResponse
                                JS={Consultar.JS}
                                codeLang={codeLang}
                                Response={Consultar.Response}></WithResponse>
                        }></Route>
                    <Route path="/docs/nom151/pdf"
                        render={() =>
                            <WithResponse
                                JS={PDF.JS}
                                codeLang={codeLang}
                                Response={PDF.Response}></WithResponse>
                        }></Route>
                    <Route path="/docs/nom151/otros"
                        render={() =>
                            <WithResponse
                                JS={Otros.JS}
                                codeLang={codeLang}
                                Response={Otros.Response}></WithResponse>
                        }></Route>
                    <Route path="/docs/documento/templates/html"
                        render={() =>
                            <WithResponse
                                JS={TemplatesHtml.JS}
                                codeLang={codeLang}
                                Response={TemplatesHtml.Response}></WithResponse>
                        }></Route>
                    <Route path="/docs/documento/templates/pdfmake"
                        render={() =>
                            <WithResponse
                                JS={TemplatesPdfMake.JS}
                                codeLang={codeLang}
                                Response={TemplatesPdfMake.Response}></WithResponse>
                        }></Route>
                    <Route path="/docs/documento/correosCertificados"
                        render={() =>
                            <WithResponse
                                JS={CorreosCertificados.JS}
                                codeLang={codeLang}
                                Response={CorreosCertificados.Response}></WithResponse>
                        }></Route>

                </div>
            </div>
        </div>
    )

}
