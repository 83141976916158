import React from 'react';
import Header from '../../shared/Header';

export function html() {
    return (
        <React.Fragment>
            <Header>Consultas</Header>
            <div className="Content">
                <div>
                    <ul>
                        <a href="/docs/consultas/cuenta"><li>Estado de paquetes contratados</li></a>
                        <a href="/docs/consultas/listar"><li>Obtener documentos</li></a>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

export default { html }
